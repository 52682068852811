.am-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.am-drawer-sidebar {
  z-index: 4;
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  will-change: transform;
  overflow-y: auto;
}
.am-drawer-draghandle {
  z-index: 1;
  position: absolute;
  background-color: rgba(50, 50, 50, 0.1);
}
.am-drawer-overlay {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
  background-color: rgba(0, 0, 0, 0.4);
}
.am-drawer-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-transition: left 0.3s ease-out, right 0.3s ease-out;
  transition: left 0.3s ease-out, right 0.3s ease-out;
}
.am-drawer.am-drawer-left .am-drawer-sidebar,
.am-drawer.am-drawer-right .am-drawer-sidebar,
.am-drawer.am-drawer-left .am-drawer-draghandle,
.am-drawer.am-drawer-right .am-drawer-draghandle {
  top: 0;
  bottom: 0;
}
.am-drawer.am-drawer-left .am-drawer-draghandle,
.am-drawer.am-drawer-right .am-drawer-draghandle {
  width: 10px;
  height: 100%;
}
.am-drawer.am-drawer-top .am-drawer-sidebar,
.am-drawer.am-drawer-bottom .am-drawer-sidebar,
.am-drawer.am-drawer-top .am-drawer-draghandle,
.am-drawer.am-drawer-bottom .am-drawer-draghandle {
  left: 0;
  right: 0;
}
.am-drawer.am-drawer-top .am-drawer-draghandle,
.am-drawer.am-drawer-bottom .am-drawer-draghandle {
  width: 100%;
  height: 10px;
}
.am-drawer.am-drawer-left .am-drawer-sidebar {
  left: 0;
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
}
.am-drawer-open.am-drawer.am-drawer-left .am-drawer-sidebar {
  -webkit-box-shadow: 1PX 1PX 2px rgba(0, 0, 0, 0.15);
          box-shadow: 1PX 1PX 2px rgba(0, 0, 0, 0.15);
}
.am-drawer.am-drawer-left .am-drawer-draghandle {
  left: 0;
}
.am-drawer.am-drawer-right .am-drawer-sidebar {
  right: 0;
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
}
.am-drawer-open.am-drawer.am-drawer-right .am-drawer-sidebar {
  -webkit-box-shadow: -1PX 1PX 2px rgba(0, 0, 0, 0.15);
          box-shadow: -1PX 1PX 2px rgba(0, 0, 0, 0.15);
}
.am-drawer.am-drawer-right .am-drawer-draghandle {
  right: 0;
}
.am-drawer.am-drawer-top .am-drawer-sidebar {
  top: 0;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
}
.am-drawer-open.am-drawer.am-drawer-top .am-drawer-sidebar {
  -webkit-box-shadow: 1PX 1PX 2px rgba(0, 0, 0, 0.15);
          box-shadow: 1PX 1PX 2px rgba(0, 0, 0, 0.15);
}
.am-drawer.am-drawer-top .am-drawer-draghandle {
  top: 0;
}
.am-drawer.am-drawer-bottom .am-drawer-sidebar {
  bottom: 0;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
}
.am-drawer-open.am-drawer.am-drawer-bottom .am-drawer-sidebar {
  -webkit-box-shadow: 1PX -1PX 2px rgba(0, 0, 0, 0.15);
          box-shadow: 1PX -1PX 2px rgba(0, 0, 0, 0.15);
}
.am-drawer.am-drawer-bottom .am-drawer-draghandle {
  bottom: 0;
}
